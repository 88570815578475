<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-8">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Manage Coupons</span>

        <span class="text-muted mt-1 fw-bold fs-7"
          >Over {{ total }} coupons</span
        >
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a coupon"
      >
        <a
          href="#"
          class="btn btn-sm btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#modal_new_coupon"
          @click="
            this.updateCouponMode = false;
            this.selectedCoupon = {};
          "
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotone/Interface/Plus-Square.svg" />
          </span>
          New Coupon
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <RemoteTable
        :columns="table.columns"
        :height="table.height"
        :data="table.data"
        :total="total"
        @size-changed="handleSizeChange"
        @current-changed="handleCurrentChange"
      >
        <template v-slot:uses="{ item }">
          <div
            class="d-flex flex-column w-100 me-2"
            data-toggle="tooltip"
            :title="'Used: ' + item.uses + ', Max: ' + item.max_uses"
          >
            <div class="d-flex flex-stack mb-2">
              <span class="text-muted me-2 fs-7 fw-bold">
                {{ parseFloat(getPercentage(item)) }}%
              </span>
            </div>

            <div class="progress h-6px w-100 bg-light-primary">
              <div
                class="progress-bar"
                :class="`bg-${getColor(item)}`"
                role="progressbar"
                :style="{ width: getPercentage(item) + '%' }"
                :aria-valuenow="getPercentage(item)"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </template>
        <template v-slot:available="{ item }">
          <span
            :class="`text-center badge-light-${
              item.available ? 'success' : 'warning'
            }`"
            class="badge fs-7 fw-bolder"
            >{{
              item.active
                ? item.available
                  ? "Available"
                  : "Expired"
                : "InActive"
            }}</span
          >
        </template>
        <template v-slot:discount="{ value, item }">
          <template v-if="item.is_free">100%</template>
          <template v-else-if="item.calculation == 'Percentage'"
            >{{ value }}%
          </template>
          <template v-else>{{ value }}$</template>
        </template>
        <template v-slot:actions="{ item }">
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#modal_new_coupon"
            @click="editCouponClicked(item)"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <span class="svg-icon svg-icon-3 svg-icon-success">
              <inline-svg src="media/icons/duotone/Communication/Write.svg" />
            </span>
          </a>

          <a
            @click="deleteCoupon(item)"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
          >
            <span class="svg-icon svg-icon-3 svg-icon-danger">
              <inline-svg src="media/icons/duotone/General/Trash.svg" />
            </span>
          </a>
        </template>
      </RemoteTable>
    </div>
  </div>
  <NewCouponModal
    :plans="plans"
    :selectedCoupon="selectedCoupon"
    :mode="updateCouponMode"
    @coupon-saved="couponSaved"
  ></NewCouponModal>
</template>

<script>
import { onMounted } from "vue";
import { setCurrentPageTitle } from "../core/helpers/breadcrumb";
import RemoteTable from "../components/RemoteTable.vue";
import NewCouponModal from "../components/modals/forms/NewCouponModal.vue";
import ApiService from "../core/services/ApiService.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  name: "Coupons",
  components: { NewCouponModal, RemoteTable },
  updateCouponMode: false,
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Coupons");
    });
  },
  mounted() {
    this.getPlans();
    this.loadCoupons();
  },
  data: function () {
    return {
      selectedCoupon: {},
      currentCoupon: {},
      total: 0,
      table: {
        height: 411,
        data: [],
        columns: [
          {
            value: "code",
            label: "Code",
            width: "100",
          },
          {
            value: "available",
            label: "Available",
            width: "110",
          },
          {
            value: "discount",
            label: "Discount",
            width: "85",
          },
          {
            value: "uses",
            label: "Uses",
            width: "100",
          },
          {
            value: "period",
            label: "Period",
            width: "70",
          },
          {
            value: "name",
            label: "Name",
            width: "160",
          },
          {
            value: "plan.interval",
            label: "Plan",
            width: "100",
          },
          {
            value: "starts_at",
            label: "Start Date",
            width: "165",
          },
          {
            value: "expires_at",
            label: "Expire Date",
            width: "165",
          },
          {
            value: "description",
            label: "Description",
            width: "300",
          },
          {
            value: "actions",
            fixed: "right",
            label: "Actions",
            width: "100",
          },
        ],
        remote: {
          url: "admin/coupons",
          method: "GET",
        },
        pageSizes: [10, 30, 50, 100],
        currentPage: 1,
        perPage: 10,
      },
      plans: [],
    };
  },
  methods: {
    getPlans() {
      ApiService.get("admin/plans", "", {
        params: {
          all: true,
        },
      }).then(({ data }) => {
        this.plans = data;
      });
    },
    loadCoupons() {
      ApiService.get(this.table.remote.url, null, {
        params: {
          page: this.currentPage,
          perPage: this.table.perPage,
        },
      }).then(({ data }) => {
        this.table.data = data.data;
        this.total = data.meta.total;
      });
    },
    handleSizeChange(perPage) {
      this.table.perPage = perPage;
      this.loadCoupons();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadCoupons();
    },
    deleteCoupon(coupon) {
      ApiService.delete(`admin/coupons/${coupon.id}`).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            text: data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            timer: 2000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.loadCoupons();
        } else {
          Swal.fire({
            text: data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    couponSaved(coupon) {
      if (!this.updateCouponMode) {
        this.table.data.push(coupon);
      } else {
        Object.assign(this.currentCoupon, coupon);
      }
    },
    getPercentage(item) {
      return ((100 * item.uses) / item.max_uses).toFixed(2);
    },
    getColor(item) {
      const percentage = this.getPercentage(item);
      if (percentage <= 30) return "primary";
      if (percentage <= 60) return "success";
      if (percentage <= 90) return "warning";
      return "danger";
    },
    editCouponClicked(coupon) {
      this.updateCouponMode = true;
      this.selectedCoupon = { ...coupon };
      this.currentCoupon = coupon;
    },
  },
};
</script>

<style scoped></style>
