
import { computed, defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";

interface CouponData {
  id: number;
  code: string;
  name: string;
  starts_at: string;
  expires_at: string;
  calculation: string;
  discount: number;
  max_uses: number;
  period: number;
  is_free: boolean;
  active: boolean;
  description: string;
}

export default defineComponent({
  name: "new-coupon-modal",
  props: ["plans", "selectedCoupon"],
  emits: ["couponSaved"],
  components: {},
  setup(props, context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const closeRef = ref<null | HTMLButtonElement>(null);

    const defaultData = {
      id: 0,
      code: "",
      name: "",
      starts_at: "",
      expires_at: "",
      calculation: "",
      discount: 0,
      max_uses: 10,
      period: 1,
      is_free: false,
      active: true,
      description: "welcome",
    };
    const targetData = ref<CouponData>({ ...defaultData });
    const coupon = computed(() => props.selectedCoupon || targetData);
    const rules = ref({
      code: [
        {
          required: true,
          message: "Please input Coupon Code",
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          message: "Please input Coupon Title",
          trigger: "blur",
        },
      ],

      starts_at: [
        {
          required: true,
          message: "Please select Start Date",
          trigger: "change",
        },
      ],

      expires_at: [
        {
          required: true,
          message: "Please select Expire Date",
          trigger: "change",
        },
      ],

      plans_ids: [
        {
          required: true,
          message: "Please select plan",
          trigger: "change",
        },
      ],
      calculation: [
        {
          required: true,
          message: "Please select calculation method",
          trigger: "change",
        },
      ],
      discount: [
        {
          required: true,
          message: "Please input discount",
          trigger: "change",
        },
      ],

      max_uses: [
        {
          required: true,
          message: "Please input Max uses",
          trigger: "change",
        },
      ],
      period: [
        {
          required: true,
          message: "Please input period",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      // console.log(targetData.value);
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let service;
          let message;
          if (coupon.value.id) {
            service = ApiService.put(
              `admin/coupons/${coupon.value.id}`,
              coupon.value
            );
            message = "Coupon updated successfully!";
          } else {
            service = ApiService.post("admin/coupons", coupon.value);
            message = "Coupon created successfully!";
          }
          return service
            .then(({ data }) => {
              Swal.fire({
                text: message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              context.emit("couponSaved", data);
              closeRef?.value?.click();
              formRef?.value?.resetFields();
              targetData.value = { ...defaultData };
              return data;
            })
            .catch((err) => {
              const response = err.response.data;
              const message = `
                        <strong>${response.message}</strong>
                        <ul>
                            ${Object.values(response.errors).map(
                              (error) => `<li>${error}</li>`
                            )}
                        </ul>`;
              console.log(message);
              Swal.fire({
                html: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .finally(() => {
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      submit,
      loading,
      formRef,
      closeRef,
      rules,
      coupon,
    };
  },
});
